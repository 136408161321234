import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: JSON.parse(localStorage.getItem("user") || "{}") || {
      id: "",
      username: "",
      rut: "",
      email: "",
      phone: "",
      lenguage: "es",
      direccion: ""
    },
    authenticated: localStorage.getItem("auth_token") ? true : false,
    token: {
      auth_token: localStorage.getItem("auth_token") || "",
      refresh_token: localStorage.getItem("refresh_token") || ""
    },
    loading: true,
    cDisabled: false,
    cDisabledF: false,
    applicationRut: localStorage.getItem("applicationRut") || "",
    tarjeta: localStorage.getItem("tarjeta") || "",
    new: true
  },
  getters: {
    fullname: state => {
      return `${state.user.username}`;
    }
  },
  mutations: {
    SET_USER(state, data) {
      Object.assign(state.user, data);
      localStorage.setItem("user", JSON.stringify(data));
      if (!state.user.lenguage) {
        state.user.lenguage = "es";
      }
    },
    UNSET_USER(state) {
      state.user = {
        id: "",
        username: "",
        rut: "",
        email: "",
        phone: "",
        lenguage: "es",
        direccion: ""
      };
      state.cDisabled = true;
      state.cDisabledF = true;
    },
    SET_TOKEN(state, data) {
      Object.assign(state.token, data);
      state.authenticated = true;
    },
    UNSET_TOKEN(state) {
      state.token = {
        auth_token: "",
        refresh_token: ""
      };
      state.authenticated = false;
    },
    SET_LENGUAGE(state, data) {
      state.user.lenguage = data;
    },
    LOADING(state, data) {
      state.loading = data;
    },
    SET_PERMIT(state, data) {
      state.cDisabled = data;
    },
    SET_PERMIT_FLOAT(state, data) {
      state.cDisabledF = data;
    },
    SET_APPLICATION_RUT(state, data) {
      state.applicationRut = data;
      localStorage.setItem("applicationRut", data);
    },
    UNSET_APPLICATION_RUT(state, data) {
      state.applicationRut = "";
      localStorage.setItem("applicationRut", "");
    },
    SET_TARJETA(state, data) {
      state.tarjeta = data;
      localStorage.setItem("tarjeta", data);
    },
    UNSET_tarjeta(state, data) {
      state.tarjeta = "";
      localStorage.setItem("tarjeta", "");
    },
    SET_NEW(state, data) {
      state.new = data;
    }
  },
  actions: {
    GET_USER({ commit, dispatch }, data) {
      const user = {
        id: data.id,
        username: data.username,
        rut: data.rut,
        email: data.email,
        phone: data.telefono,
        lenguage: data.idioma,
        direccion: data.direccion
      };
      commit("SET_USER", user);
    },
    LOGOUT({ commit, dispatch }) {
      localStorage.removeItem("auth_token");
      localStorage.removeItem("refresh_token");
      commit("UNSET_USER");
      const user = {
        id: "",
        name: "",
        family_name: "",
        rut: "",
        email: "",
        phone: "",
        lenguage: "",
        direccion: ""
      };
      commit("SET_USER", user);
      commit("UNSET_TOKEN");
    },
    GET_PERMIT({ commit, dispatch }, list) {
      if (!list.length) {
        commit("SET_NEW", true);
        commit("SET_PERMIT", false);
        commit("SET_PERMIT_FLOAT", false);
      } else {
        commit("SET_NEW", false);
        const familyHead = list.find(
          (element: any) => element.jefeFamilia === "si"
        );
        const floatType = list.find((element: any) => element.tipoVecino !== 1);
        if (familyHead) {
          if (floatType) {
            if (list.length === 1) {
              commit("SET_PERMIT", true);
              commit("SET_PERMIT_FLOAT", true);
            } else {
              commit("SET_PERMIT", false);
              commit("SET_PERMIT_FLOAT", false);
            }
          } else {
            commit("SET_PERMIT", false);
            commit("SET_PERMIT_FLOAT", false);
          }
        } else {
          commit("SET_PERMIT", true);
          commit("SET_PERMIT_FLOAT", false);
        }
      }
    }
  }
});
